<template>
    <div class="index-container">
        <div class="course-content">
            <div class="news-top">{{newsContentTitle}}</div>
            <div class="news-box">
                <el-form :model="newsForm" :rules="newsRules" ref="newsForm" label-width="100px">
                    <el-scrollbar class="news-box" style="height: 1%; flex: 1" :native="false">
                        <el-form-item label="新闻标题" prop="title">
                            <el-input v-model="newsForm.title" style="width: 500px"></el-input>
                        </el-form-item>
                        <el-form-item label="副标题" prop="subtitle">
                            <el-input type="textarea" v-model="newsForm.subtitle" rows="5" style="width: 500px"></el-input>
                        </el-form-item>
                        <el-form-item label="新闻封面" prop="cover" class="el-form-item-cover">
                            <el-upload class="news-uploader"
                                    :action="uploadCover"
                                    :headers="headersParams"
                                    name="newsCover"
                                    :show-file-list="false"
                                    :on-success="handleCoverSuccess"
                                    :before-upload="beforeCoverUpload">
                                <img v-if="imgSrc" :src="imgSrc" class="news-cover">
                                <i v-else class="el-icon-plus news-uploader-icon"></i>
                            </el-upload>
                            <span class="news-prompt">建议尺寸：328*200</span>
                        </el-form-item>
                        <el-form-item label="新闻内容" prop="content">
                            <div id="editor-w"></div>
                        </el-form-item>
                        <el-form-item label="推荐首页" prop="delivery">
                            <el-switch v-model="newsForm.delivery" :active-value="1" :inactive-value="0"></el-switch>
                        </el-form-item>
                        <el-form-item label="文章排序" prop="sort">
                            <el-input v-model.number="newsForm.sort" style="width: 100px"></el-input>
                            <span class="news-prompt">文章排序在0-255数值之间</span>
                        </el-form-item>
                    </el-scrollbar>
                    <el-form-item class="el-form-bottom">
                        <el-button type="primary" @click="submitForm('newsForm')">立即提交</el-button>
                        <el-button @click="resetForm('newsForm')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import WE from "../../utils/wEditor";
    export default {
        data() {
            return {
                newsId: Number(this.$route.query.newsId) || '',
                newsForm: {
                    title: '',
                    subtitle: '',
                    cover: '',
                    content: '',
                    sort: 1,
                    delivery: 0,
                },
                newsRules: {
                    title: [
                        { required: true, message: '请输入新闻标题', trigger: 'blur' },
                        { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
                    ],
                    subtitle: [
                        { required: true, message: '请输入新闻副标题', trigger: 'blur' }
                    ],
                    cover: [
                        { required: true, message: '请选择新闻封面', trigger: 'change' }
                    ],
                    content: [
                        { required: true, message: '请输入新闻内容', trigger: 'blur' }
                    ],
                    sort: [
                        { type: 'number', message: '请输入文章排序，1-255之间的数值', trigger: 'blur' },
                        { pattern: /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]?[0-9])$/, message: '范围在1-255', trigger: 'blur'},
                    ],
                },
                editorW: null,
                uploadCover: this.$api.newsUpload,
                headersParams: {
                    Authorization: localStorage.getItem('token')
                },
                imgSrc: null,
                newsContentTitle: '新增新闻',
            };
        },
        mounted() {
            this.getNewsInfo()
            this.editorW = new WE('#editor-w', this.$api.newsWeUpload);
        },
        methods: {
            getNewsInfo() {
                if (this.newsId) {
                    this.newsContentTitle = '编辑新闻'
                    let params = {
                        id: this.newsId
                    }
                    this.$http.axiosGetBy(this.$api.newsInfo, params, (res) => {
                        if (res.code === 200) {
                            this.newsForm = res.data;
                            this.imgSrc = res.data.imgSrc;
                            this.editorW.setWEHtml(this.newsForm.content);
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
            },
            submitForm(formName) {
                this.newsForm.content = this.editorW.getWEHtml();
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('title', this.newsForm.title);
                        formData.append('subtitle', this.newsForm.subtitle);
                        formData.append('cover', this.newsForm.cover);
                        formData.append('content', this.editorW.getWEHtml());
                        formData.append('sort', this.newsForm.sort);
                        formData.append('delivery', Number(this.newsForm.delivery));
                        if (this.newsId) {
                            formData.append('id', this.newsId);
                        }
                        this.$http.axiosPost(this.$api.newsAdd, formData, (res) => {
                            if (res.code === 200) {
                                this.$message.success(res.msg);
                                this.$router.push('/admin/newsManage/index');
                            } else {
                                this.$message.error(res.msg)
                            }
                        }, (err) => {
                            console.log(err)
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.imgSrc = null
            },
            handleCoverSuccess(res, file) {
                if (res.code === 200) {
                    this.imgSrc = res.imgSrc;
                    this.newsForm.cover = res.src
                } else {
                    this.$message.error('图片上传失败，请稍后再试~')
                }
            },
            beforeCoverUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isLt2M;
            }
        }
    }
</script>

<style scoped lang="scss">
    .course-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        .news-top {
            padding: 20px;
            border-bottom: 1px solid #EAEAEA;
        }
        .news-box {
            height: 1%;
            flex: 1;
            overflow: hidden;
            .el-form {
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: hidden;
                .el-form-bottom {
                    border-top: 1px solid #EAEAEA;
                    padding: 20px;
                    margin-bottom: 0;
                }
                .el-form-item-cover {
                    ::v-deep .el-form-item__content {
                        display: flex;
                        align-items: flex-end;
                    }
                }
            }
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    padding: 20px 20px 0;
                }
            }
        }
    }
    .news-prompt {
        color: #F56C6C;
        margin-left: 10px;
    }
    .news-uploader {
        display: inline-block;
        ::v-deep .el-upload {
            /*background: #fbfdff;*/
            width: 328px;
            height: 200px;
            border: 1px solid #d9d9d9;
            border-radius: 6px;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                border-color: #409EFF;
            }
            .news-uploader-icon {
                font-size: 28px;
                color: #8c939d;
            }
            .news-cover {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
</style>